import React, { useState }  from "react";
import "./deals.css";
// eslint-disable-next-line no-unused-vars
import { THEME_CONSTANT } from "../../lib/themeConstants";
import { useTranslation } from "react-i18next";
import vectorImage from "../../assets/images/Vector.png";

const Deals = () => {
  const [copiedCode, setCopiedCode] = useState(null);
  const copyToClipboard = (code) => {
    navigator.clipboard.writeText(code).then(
      () => {
        setCopiedCode(code);
        setTimeout(() => {
          setCopiedCode(null);
        }, 2000); // Reset after 2 seconds
      },
      () => {
        alert("Failed to copy code");
      }
    );
  };
  const deals = [
    {
      title: "DEAL FOR A MEAL",
      price: "23",
      items1: "1X LARGE CLASSIC PIZZA",
      items2: "1X CLASSIC SIDE & 1x 330 ml DRINK",
      items3: "",
      code: "DEALFORAMEAL",
      image: require("../../assets/images/dealone.png"),
    },
    {
      title: "WHANAU",
      price: "41",
      items1: "2X LARGE CLASSIC PIZZA",
      items2: "1X CLASSIC SIDE ",
      items3: "1X 1.5L DRINK",
      code: "WHANAU41",
      image: require("../../assets/images/dealtwo.png"),
    },
    {
      title: "THREE FOR",
      price: "47",
      items1: "3X LARGE CLASSIC PIZZA",
      items2: "",
      items3: "",
      code: "5FOR47",
      image: require("../../assets/images/dealthree.png"),
    },
  ];
  
  // eslint-disable-next-line no-unused-vars
  const { t } = useTranslation();
  return (
    <div>
    <div className="flex justify-center items-center min-h-screen bg-white">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-[1px] w-full">
        {deals.map((deal, index) => (
          <React.Fragment key={index}>
            <div className="bg-[#212121] text-white px-0 pt-20 pb-10 sm:pb-10 shadow-lg flex flex-col items-center sm:min-h-screen w-full">
              <div className="flex flex-row w-full justify-center items-center mb-4 ">
                <h3 className="text-white text-3xl md:text-2xl lg:text-2xl xl:text-4xl font-medium tracking-wider">
                  {deal.title}
                </h3>
                <div className="relative w-20 py-1 md:w-16 lg:w-20 ml-2 md:px-12 md:py-1 lg:px-16 lg:py-2">
                  <div
                    className="absolute inset-0 bg-cover bg-center"
                    style={{
                      backgroundImage: `url(${vectorImage})`,
                    }}
                  />
                  <div className="relative z-10 flex h-full w-full items-center justify-center">
                    <h1 className="text-3xl md:text-2xl lg:text-2xl xl:text-4xl font-bold text-black">
                    ${deal.price}*
                    </h1>
                  </div>
                </div>
              </div>

              <div className="flex h-28">
                <div className="my-5">
                  <h1 className="text-md lg:text-lg xl:text-xl leading-6 tracking-normal text white font-[sora]">
                    {deal.items1}
                  </h1>
                  <h1 className=" text-md lg:text-lg xl:text-xl leading-6 tracking-normal text white font-[sora]">
                    {deal.items2}
                  </h1>
                  <h1 className=" text-md lg:text-lg xl:text-xl leading-6 tracking-normal text white font-[sora]">
                    {deal.items3}
                  </h1>
                </div>
              </div>
              <div className="relative inline-block cursor-pointer group">
                <div className="bg-gray-800 text-white p-4 rounded border-2 border-dashed font-semibold text-md lg:text-lg xl:text-xl font-[sora] tracking-widest">
                  CODE -{" "}
                  
                    <span
                      className="font-semibold text-md lg:text-lg xl:text-xl font-[sora] text-yellow-400 tracking-widest"
                      onClick={() => copyToClipboard(deal.code)}
                    >
                      {deal.code}
                    </span>
                    <span
                      className={`absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 ${
                        copiedCode === deal.code
                          ? "block"
                          : "hidden group-hover:block"
                      } bg-gray-800 text-white text-xs rounded py-1 px-2 whitespace-no-wrap w-full`}
                    >
                      {copiedCode === deal.code
                        ? "Copied!"
                        : "Click to copy the code"}
                    </span>
                  </div>  
                </div>
              <img src={deal.image} alt={deal.title} className="mt-10" />
            </div>
            {/* Add hr line between items on mobile screens */}
            {index < deals.length - 1 && (
              <hr className="lg:hidden border-t border-gray-300 " />
            )}
          </React.Fragment>
        ))}
      </div>
      
    </div>
    {/* New section for the text note */}
    <div className="note-section w-full bg-[#212121] py-4 text-white flex justify-center items-center">
        <p className="text-center text-sm sm:text-md lg:text-lg font-[sora]">
        *$2 EXTRA CHARGE APPLIES FOR EACH
PREMIUM PIZZA SELECTION. EXTRA CHARGES ARE
APPLICABLE FOR CUSTOMIZATIONS SUCH AS
SELECTION OF PREMIUM SIDES, GLUTEN-FREE CRUST
OR ADDITIONAL TOPPINGS, ETC.

        </p>
      </div>
    </div>
  );
};

export default Deals;
