import { baseApi } from "./base.service";

const COMBO_TAG = "COMBO";

const comboApi = baseApi
  .enhanceEndpoints({
    addTagTypes: [COMBO_TAG],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      // Mutation for adding combo deal
      addComboDeal: build.mutation({
        query: (data) => ({
          url: "/combo-deals", // API endpoint
          method: "POST",
          body: data, // data to send in the POST request
        }),
        onQueryStarted: async (data, { dispatch, queryFulfilled }) => {
          try {
            await queryFulfilled;
            // Optionally invalidate tags or dispatch actions to update the state
            dispatch(comboApi.util.invalidateTags([COMBO_TAG]));
          } catch (err) {
            console.error("Error adding combo deal:", err);
          }
        },
      }),
    }),
    overrideExisting: false,
  });

export const { useAddComboDealMutation } = comboApi;
export { comboApi };
