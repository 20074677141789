import { createSlice } from "@reduxjs/toolkit";
import { comboApi } from "../../services/combo-deal.service"; // Now it should work

// Initial state for the combo slice
const initialState = {
  comboDeals: [],
  status: "idle", // idle | loading | succeeded | failed
  error: null,
};

// Create a slice for the combo feature
const combo = createSlice({
  name: "combo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(comboApi.endpoints.addComboDeal.matchPending, (state) => {
        state.status = "loading";
      })
      .addMatcher(
        comboApi.endpoints.addComboDeal.matchFulfilled,
        (state, action) => {
          state.status = "succeeded";
          state.comboDeals = action.payload;
        }
      )
      .addMatcher(
        comboApi.endpoints.addComboDeal.matchRejected,
        (state, action) => {
          state.status = "failed";
          state.error = action.error.message;
        }
      );
  },
});

export const selectComboDeals = (state) => state.combo.comboDeals;
export const selectComboStatus = (state) => state.combo.status;
export const selectComboError = (state) => state.combo.error;

export default combo.reducer;
