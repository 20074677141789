export const THEME_CONSTANT = {
  COLOR: {
    PRIMARY: "#EC643C",
    BODY_BACKGROUND: "#FFFFFF",
    PRIMARY_BLACK: "#1D1D1B",
    SECONDARY_BLACK: "#333333",
    PRIMARY_WHITE: "#F9F4EA",
    SECONDARY_WHITE: "#FFFFFF",
    PLACEHOLDER_GREY: "#ADADAD",
    PRIMARY_ORANGE: "#ec643c",
    DISABLED_GREY: "#4b48487a",
    SECONDARY_GREY: "#ADADAD26",
    NEW_GOLD: "#FDCF72"
  },
  FONT_SIZE: {
    EXTRA_SMALL: "0.8em",
    SMALL: "0.9em",
    NORMAL: "1em",
    NORMAL_LARGE: "1.1em",
    MEDIUM: "1.2em",
    HEADING: "1.4em",
    LARGE: "1.5em",
    MEDIUM_LARGE: "1.6em",
    EXTRA_LARGE: "1.8em",
    TITLE: "2em",
  },
};
