import { baseApi } from "./base.service";

const CART_TAG = "CART";

const cartApi = baseApi
  .enhanceEndpoints({
    addTagTypes: [CART_TAG],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getCart: build.query({
        query: ({ type }) => ({
          url: `/cart`,
          method: "GET",
          params: { type },
          force: false,
        }),
      }),
      addCart: build.mutation({
        query: (data) => ({
          url: `/cart`,
          method: "POST",
          body: data,
        }),
        onQueryStarted: async (data, { dispatch, queryFulfilled }) => {
          try {
            await queryFulfilled;
            // Optionally invalidate tags to refetch updated data
            dispatch(cartApi.util.invalidateTags([CART_TAG]));
          } catch (err) {
            // Handle the error
            console.error("Add Cart Error:", err);
          }
        },
      }),
      updateCart: build.mutation({
        query: (data) => ({
          url: `/cart/${data?.id}`,
          method: "PATCH",
          body: data?.cartData,
        }),
        onQueryStarted: async (data, { dispatch, queryFulfilled }) => {
          const patchResult = dispatch(
            cartApi.util.updateQueryData(
              "getCart",
              { type: data.type },
              (draft) => {
                // Apply optimistic update logic
                Object.assign(draft, data.cartData);
              }
            )
          );
          try {
            await queryFulfilled;
            // Optionally invalidate tags to refetch updated data
            dispatch(cartApi.util.invalidateTags([CART_TAG]));
          } catch (err) {
            // Undo the optimistic update
            patchResult.undo();
            console.error("Update Cart Error:", err);
          }
        },
      }),
      deleteCartItem: build.query({
        query: (id) => ({
          url: `/cart/${id}`,
          method: "DELETE",
        }),
        onQueryStarted: async (id, { dispatch, queryFulfilled }) => {
          const patchResult = dispatch(
            cartApi.util.updateQueryData("getCart", undefined, (draft) => {
              // Optimistically remove the item from the cache
              delete draft[id];
            })
          );
          try {
            await queryFulfilled;
            dispatch(cartApi.util.invalidateTags([CART_TAG]));
          } catch (err) {
            patchResult.undo();
            console.error("Delete Cart Item Error:", err);
          }
        },
      }),
      placeOrder: build.mutation({
        query: (data) => ({
          url: `/order`,
          method: "POST",
          body: data,
        }),
      }),
      DeliveryTimeCheck: build.mutation({
        query: (data) => ({
          url: `/order`,
          method: "POST",
          body: data,
        }),
      }),
      billOrder: build.mutation({
        query: (data) => ({
          url: `/checkout`,
          method: "POST",
          body: data,
        }),
      }),
    }),
    overrideExisting: false,
  });

export const {
  useLazyGetCartQuery,
  useAddCartMutation,
  useUpdateCartMutation,
  useLazyDeleteCartItemQuery,
  usePlaceOrderMutation,
  useBillOrderMutation,
  useDeliveryTimeCheckMutation,
} = cartApi;

export default cartApi;
